import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/terms',
    component: lazy(() => import('src/views/terms/index'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/login/:id',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/admin/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
    // Forgot Password
    {
      exact: true,
      // guard: GuestGuard,
      path: '/forgotpassword',
      component: lazy(() => import('src/views/auth/ForgotPasswordView'))
    },
    {
      exact: true,
      // guard: GuestGuard,
      path: '/forgotpassword/:id',
      component: lazy(() => import('src/views/auth/ForgotPasswordView'))
    },
    {
      exact: true,
      path: '/forgotpassword-unprotected',
      component: lazy(() => import('src/views/auth/ForgotPasswordView'))
    },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [

      // job queue

      {
        exact: true,
        path: '/app/jobqueue',
        component: lazy(() => import('src/views/jobqueue/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/jobqueue/create',
        component: lazy(() => import('src/views/jobqueue/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/jobqueue/create/:id',
        component: lazy(() => import('src/views/jobqueue/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/jobqueue/builder',
        component: lazy(() => import('src/views/jobqueue/Builder'))
      },

      // module creation
      {
        exact: true,
        path: '/app/module',
        component: lazy(() => import('src/views/module/listview'))
      },
      {
        exact: true,
        path: '/app/module/1',
        component: lazy(() => import('src/views/module/AccountView'))
      },

      // ticket
      {
        exact: true,
        path: '/app/ticket',
        component: lazy(() => import('src/views/ticket/KanbanView'))
      },

      // Configure project
      {
        exact: true,
        path: '/app/project',
        component: lazy(() => import('src/views/projects/listview'))
      },

      {
        exact: true,
        path: '/app/project/1',
        component: lazy(() => import('src/views/projects/AccountView'))
      },

      // knowledge base
       {
        exact: true,
        path: '/app/knowledgebase',
        component: lazy(() => import('src/views/knowledgebase/CustomerListView'))
      },

      {
        exact: true,
        path: '/app/knowledgebase/1',
        component: lazy(() => import('src/views/knowledgebase/AccountView'))
      },

      {
        exact: true,
        path: '/app/knowledgebase/view/1',
        component: lazy(() => import('src/views/knowledgebase/CustomerDetailsView'))
      },


      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: [
          '/app/chat/new',
          '/app/chat/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      // {
      //   exact: true,
      //   path: '/app/management/employeelist',
      //   component: lazy(() => import('src/views/employeelist/EmployeeListView'))
      // },
      {
        exact: true,
        path: '/app/management/sos',
        component: lazy(() => import('src/views/sos/SosListView'))
      },
      {
        exact: true,
        path: '/app/management/monitoring',
        component: lazy(() => import('src/views/monitering/MoniteringListView'))
      },
      
      {
        exact: true,
        path: '/app/management/admin',
        component: lazy(() => import('src/views/admin/AdminListView'))
      },
      {
        exact: true,
        path: '/app/management/admin/:customerId',
        component: lazy(() => import('src/views/admin/AdminDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/checkpointmaster',
        component: lazy(() => import('src/views/checkpointmaster/CheckpointmasterListView'))
      },
      {
        exact: true,
        path: '/app/management/checkpointmaster/:customerId',
        component: lazy(() => import('src/views/checkpointmaster/CheckpointmasterDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/shift',
        component: lazy(() => import('src/views/shift/ShiftListView'))
      },
      {
        exact: true,
        path: '/app/management/shift/:customerId',
        component: lazy(() => import('src/views/shift/ShiftDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/vendor/:customerId/edit',
        component: lazy(() => import('src/views/vendor/VendorEditView'))
      },
      {
        exact: true,
        path: '/app/management/vendor',
        component: lazy(() => import('src/views/vendor/VendorListView'))
      },
      {
        exact: true,
        path: '/app/management/vendor/:customerId',
        component: lazy(() => import('src/views/vendor/VendorDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/vendor/:customerId/edit',
        component: lazy(() => import('src/views/vendor/VendorEditView'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/routelist',
        component: lazy(() => import('src/views/routelist/RoutelistView'))
      },

      {
        exact: true,
        path: '/app/management/request',
        component: lazy(() => import('src/views/requests/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/routelist/:customerId',
        component: lazy(() => import('src/views/routelist/RouteEditView'))
      },
      {
        exact: true,
        path: '/app/management/routelist/:id/edit',
        component: lazy(() => import('src/views/routelist/RouteEditView'))
      },
      {
        exact: true,
        path: '/app/management/routelist/add',
        component: lazy(() => import('src/views/routelist/RouteEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
 
  {
    path: '*',
    // layout: MainLayout,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
