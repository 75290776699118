import React, {
  useRef,
  useState
} from 'react';
import type {
  ChangeEvent,
  FC,
  KeyboardEvent
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Divider,
  IconButton,
  Input,
  Paper,
  SvgIcon,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Send as SendIcon } from 'react-feather';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { sosAdminChat } from 'src/slices/service/socket.service';
import {  sosAdminChatListen } from 'src/slices/sos';
import { useDispatch } from 'src/store';

interface MessageComposerProps {
  className?: string;
  disabled?: boolean;
  onSend?: (value: string) => void;
  adminImage?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    padding: "4px 5px"
  },
  inputContainer: {
    flexGrow: 1,
    marginLeft: "5px",
    padding: "1px"
  },
  divider: {
    height: 24,
    width: 1
  },
  fileInput: {
    display: 'none'
  },
  avt:{
    height:"25px",
    width:'25px',
  }
}));

const MessageComposer: FC<MessageComposerProps> = ({
  className,
  disabled,
  onSend,
  adminImage,
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {selectedChatId} = useSelector(state => state.sos);
  const [body, setBody] = useState<string>('');
  const dispatch = useDispatch();
  const handleAttach = (): void => {
    fileInputRef.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setBody(event.target.value);
  };
  

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const handleSend = (): void => {
    if (!body) {
      return;
    }

    if (onSend) {
      onSend(body);
      // sosAdminChat((cv) => {
      //   console.log("sosAdminChat",cv)
      //   dispatch(sosAdminChatListen(cv))
      // });
    }

    setBody('');
  };

  return (
    <div className={clsx(classes.root, className)} {...rest} >
      <Avatar alt="Person" className={classes.avt} src={adminImage} />

      <Paper variant="outlined" className={classes.inputContainer} >
        <Input style={{height:"30px"}} disableUnderline fullWidth value={body}
          onChange={handleChange} onKeyUp={handleKeyUp} placeholder="Leave a message" />
      </Paper>

      <Tooltip title="Send">
        <span>
          <IconButton color="secondary" onClick={handleSend} >
            <SvgIcon fontSize="small">
              <SendIcon />
            </SvgIcon>
          </IconButton>
        </span>
      </Tooltip>

      {/* <Divider className={classes.divider} />

      <Tooltip title="Attach photo">
        <span>
          <IconButton edge="end" onClick={handleAttach} >
            <AddPhotoIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Attach file">
        <span>
          <IconButton edge="end" onClick={handleAttach} >
            <AttachFileIcon />
          </IconButton>
        </span>
      </Tooltip> */}

      {/* <input className={classes.fileInput} ref={fileInputRef} type="file" /> */}
    </div>
  );
};

MessageComposer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSend: PropTypes.func
};

MessageComposer.defaultProps = {
  disabled: false,
  onSend: () => {}
};

export default MessageComposer;
