import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { me } from './auth';
import { SosStateData } from 'src/types/sos';

const initialState: SosStateData = {
    sosList: [],
    sosTotalCount: 0,
    selectedConversations: [],
    selectedChatId: null,
    chatOpenStatus: null,
    loader: false,
    deleteRecId: null,
    chatList: [],
    chatTotalCount: 0,
    chatLoader: false,
    userSelectedChatList: []
};

const slice = createSlice({
    name: 'sos',
    initialState,
    reducers: {
        setSOSList(state: SosStateData, action: PayloadAction<{ sosList: any[], totalCount: number }>) {
            state.sosList = action.payload.sosList;
            state.sosTotalCount = action.payload.totalCount;
        },
        setSelectedConversation(state: SosStateData, action: PayloadAction<{ selectedConversations: any }>) {
            state.selectedConversations = action.payload.selectedConversations
        },
        setChatList(state: SosStateData, action: PayloadAction<{ chatList: any[], chatTotalCount: number }>) {
            state.chatList = action.payload.chatList;
            state.chatTotalCount = action.payload.chatTotalCount;
        },
        loader(state: SosStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setChatLoader(state: SosStateData, action: PayloadAction<any>) {
            state.chatLoader = action.payload.chatLoader
        },
        setDeleteRecordId(state: SosStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setSelectedChatId(state: SosStateData, action: PayloadAction<{ selectedChatId: string | number }>) {
            state.selectedChatId = action.payload.selectedChatId
        },
        setChatOpenStatus(state: SosStateData, action: PayloadAction<{ chatOpenStatus: boolean }>) {
            state.chatOpenStatus = action.payload.chatOpenStatus
        },
        setAdditionalConversation(state: SosStateData, action: PayloadAction<{ additionalConversation: any }>) {
            let conversationArray: any[] = [...current(state).selectedConversations];
            let additionalConversation: any = action.payload.additionalConversation;

            if (additionalConversation?.id) {
                conversationArray = [...conversationArray, additionalConversation]
            }

            state.selectedConversations = [...conversationArray];
        },
        setUserSelectedChatList(state: SosStateData, action: PayloadAction<{ selectedSosObject: any }>) {
            let chatArray: any[] = [...current(state).userSelectedChatList];
            let sosObject: any = action.payload.selectedSosObject;
            let finalChatArray: any[] = [...chatArray];

            if (chatArray.length) {
                let idExistsArray: any[] = chatArray.filter((element) => { return element?.sosChat?.id == sosObject?.sosChat?.id });
                if (idExistsArray.length == 0) finalChatArray = [sosObject, ...finalChatArray];

            } else {
                finalChatArray = [...finalChatArray, sosObject];
            }

            state.userSelectedChatList = [...finalChatArray];
        },
        deleteUserSelectedChat(state: SosStateData, action: PayloadAction<{ chatId: number | string }>) {
            let chatArray: any[] = [...current(state).userSelectedChatList];
            let selectedChatId: number | string = current(state).selectedChatId;
            let chatId: number | string = action.payload.chatId;
            let finalChatArray: any[] = [];

            if (chatArray.length) {
                chatArray.forEach(element => {
                    if (element?.sosChat?.id != chatId) {
                        finalChatArray.push(element);
                    }
                    console.log('deleteUserSelectedChat==========>', element, selectedChatId, chatId)

                });

                if (selectedChatId == chatId) {
                    state.selectedChatId = null;
                    state.selectedConversations = [];
                }
                console.log('deleteUserSelectedChat==========>', selectedChatId, chatId)

                if (finalChatArray.length == 0) {
                    state.selectedConversations = [];
                    state.selectedChatId = null;
                    state.chatOpenStatus = null;
                }
            }


            state.userSelectedChatList = [...finalChatArray];
        },

        updateSOSSocketResolved(state: SosStateData, action: PayloadAction<{ chat: any }>) {
            let sosChatList = current(state).sosList
            let newSosList = []
            for (const sosChat of sosChatList) {
                if (sosChat.id == action.payload.chat.id) {
                    newSosList.push(action.payload.chat)
                }else {
                    newSosList.push(sosChat)
                }
            }
            state.sosList = [...newSosList]
        },

        addSOSSocketInitiated(state: SosStateData, action: PayloadAction<{ chat: any }>) {
            let sosChatList = current(state).sosList
            console.log('========= chat ', action.payload.chat)
            // let newSosList = []
            // for (const sosChat of sosChatList) {
            //     if (sosChat.id == action.payload.chat.id) {
            //         newSosList.push(action.payload.chat);
            //     }else {
            //         newSosList.push(sosChat);
            //     }
            // }
            // state.sosList = [...newSosList];

            let chat = action.payload.chat
            state.sosList = [chat, ...sosChatList]
            
        },
        
        addSosListenData(state: SosStateData, action: PayloadAction<{ chat: any }>) {
            let sosChatList = current(state).selectedConversations
            state.selectedConversations = [...sosChatList, action.payload.chat ];
            console.log('======= state.selectedConversations ', action.payload)
        },

        addSOSAdminChat(state: SosStateData, action: PayloadAction<{ chat: any }>) {
            let sosChatList = current(state).selectedConversations
            console.log('============== action.payload ', action.payload, sosChatList)
        },

        addSOSEmployeeChat(state: SosStateData, action: PayloadAction<{ chat: any }>) {
            console.log('============== action.payload ', action.payload)
        }

    }
});

export const reducer = slice.reducer;

export const getAllSOS = (page: number = 0, limit: number = 10, searchKey: string = '', currentTab: string = 'All'): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/sos?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&chatStatus=${currentTab}`, {}, {});

    if (response) {
        dispatch(slice.actions.setSOSList({ sosList: response?.data || [], totalCount: response?.totalCount || 0 }));
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const sosChatUpdate = (eventData: any) => (dispatch) => {
    if (eventData && eventData.eventName && eventData.eventName == 'SOS_CHAT_INITIATED') {
        dispatch(slice.actions.addSOSSocketInitiated({ chat: eventData.data }))
    }
    else if(eventData && eventData.eventName && eventData.eventName == 'SOS_CHAT_RESOLVED'){
        dispatch(slice.actions.updateSOSSocketResolved({ chat: eventData.data }))
    }
}

export const sosListenChat = (eventData: any) => (dispatch) => {
    dispatch(slice.actions.addSosListenData({ chat: eventData.data }))
}

export const sosAdminChatListen = (eventData: any) => (dispatch) => {
    console.log('==== eventDataeventData ', eventData)
    if (eventData && eventData.eventName && eventData.eventName == 'SOS_CHAT_ADMIN') {
        dispatch(slice.actions.addSOSAdminChat({ chat: eventData }))
    }
}

export const sosEmployeeChatListen = (eventData: any) => (dispatch) => {
    dispatch(slice.actions.addSOSEmployeeChat({ chat: eventData.data }))
}


export const asignChatToAdmin = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/sos/asign/${id}`, {}, {});

    return response?.data?.id ? true : false;
};

export const getAllChatList = (skip: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setChatLoader({ chatLoader: true }));

        const response: any = await AXIOS_GET(`/api/sos/chat?page=${skip}&limit=${limit}&searchKey=${searchKey}`, {}, {});

        dispatch(slice.actions.setChatList({ chatList: response?.data || [], chatTotalCount: response?.totalCount || 0 }));

    } catch {
        dispatch(slice.actions.setChatLoader({ chatLoader: false }));
    }
};

export const getChatConversationByChatId = (id): AppThunk => async (dispatch) => {
    dispatch(resetSelectedConversation());

    const response: any = await AXIOS_GET(`/api/sos/chatMessage/${id}`, {}, {});

    dispatch(slice.actions.setSelectedConversation({ selectedConversations: (response?.data || []) }));
    dispatch(setUserSelectedChatList(response?.data[0] || []));

    return response?.data?.id ? true : false;
};

export const sendMessage = (chatId: number | string, message: string = ''): AppThunk => async (dispatch) => {
    let payload = { "message": message };

    const response: any = await AXIOS_POST(`/api/sos/admin/chat/${chatId}`, payload, {});
    dispatch(slice.actions.setAdditionalConversation({ additionalConversation: (response?.data || []) }));

}

export const deleteSOSById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/users/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;

};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const setChatId = (id) => (dispatch) => {
    dispatch(slice.actions.setSelectedChatId({ selectedChatId: id ? id : null }));
}

export const setChatBoxOpenStatus = (status: boolean = true) => (dispatch) => {
    dispatch(slice.actions.setChatOpenStatus({ chatOpenStatus: status == true ? true : false }));
}


export const resetSelectedConversation = () => (dispatch) => {
    dispatch(slice.actions.setSelectedConversation({ selectedConversations: [] }))
}

export const setUserSelectedChatList = (sosObject: any) => (dispatch) => {
    dispatch(slice.actions.setUserSelectedChatList({ selectedSosObject: { ...sosObject } }))
}

export const deleteUserSelectedChat = (chatId: any) => (dispatch) => {
    dispatch(slice.actions.deleteUserSelectedChat({ chatId: chatId }))
}



/* #endregion custom functions */

export default slice;
