import React, { FC, useEffect } from 'react'
import type { Theme } from 'src/theme';
import {
  Box, Divider, Typography, makeStyles, Modal, Fab, Avatar, TextField
} from '@material-ui/core';
import {Remove as RemoveIcon, CloseTwoTone as CloseRoundedIcon} from '@material-ui/icons';
import Thread from './chat/ChatView/Thread';
import { useDispatch, useSelector } from 'src/store';
import NavigationIcon from '@material-ui/icons/Navigation';
import MessageList from './chat/ChatView/Thread/MessageList';
import MessageComposer from './chat/ChatView/Thread/MessageComposer';
import { setChatBoxOpenStatus, sendMessage, getChatConversationByChatId, setChatId,
  deleteUserSelectedChat } from 'src/slices/sos';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import { sosChatListen, sosAdminChat, sosEmployeeChat } from 'src/slices/service/socket.service';
import {  sosListenChat } from 'src/slices/sos';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // marginLeft:"5px"
  },
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh !important'
  },
  closeicn: {
    textAlign: "end",
    padding: "5px",
    marginTop: '-5px',
    cursor: "pointer"
  },
  minicon: {
    textAlign: "end",
    padding: "10px",
    marginTop: '-5px',
    cursor: "pointer"
  },
  modalDetails: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center"
  },
  modalpaper: {
    position: 'fixed',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "10px",
    width: "75vh",
    height: "80vh",
    // overflowY: "scroll",
    border: "none",
    bottom: "2%",
    right: "1%",
    borderRadius: '8px',
  },
  sidebar: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  chat: {
    position: "fixed",
    right: 5,
    bottom: 5
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  // avatar: {
  //   height: 32,
  //   width: 32
  // },
  image: {
    cursor: 'pointer',
    height: 'auto',
    maxWidth: '100%',
    width: 380
  },
  chatlist: {
    display: 'flex',
    width: "270px",
    paddingBottom: "5px",
    paddingTop: '5px',
    justifyContent: 'space-between',
    paddingLeft: "5px",
  },
  chatlistselect: {
    display: 'flex',
    paddingBottom: "5px",
    paddingTop: '5px',
    justifyContent: 'space-between',
    paddingLeft: "5px",
    // backgroundColor: "#a90013",
    // color: "white",
    cursor: 'pointer'
  },
  seacrhbox: {
    marginTop: "13px",
    marginBottom: "13px",
    height: '35px',
    right: 8,
    paddingLeft: "10px"
  },
  chats: {
    overflowY: "scroll",
    height: "68vh"
  }
}));

const Floatingchat: FC<any> = ({ ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { chatOpenStatus, selectedChatId, userSelectedChatList } = useSelector(state => state.sos);
  const [open, setOpen] = React.useState(chatOpenStatus);

  const handleOpen = (method: string = 'default') => {
    setOpen(true);

    if (method === 'default') {
      dispatch(setChatBoxOpenStatus(true));
    }
  };

  const handleClose = () => {
    dispatch(setChatBoxOpenStatus(false));
    setOpen(false);
  };

  const handleRefresh = (chatId) => {
    dispatch(getChatConversationByChatId(chatId));

  }

  const handleSendMessage = async (value: string): Promise<void> => {
    try {
      dispatch(sendMessage(selectedChatId, value));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (chatOpenStatus) {
      handleOpen('edit');
      sosChatListen((cv) => {
        console.log("chat listen",cv)
        dispatch(sosListenChat(cv))
      });
  
    } else {
      setOpen(false);
    }
  }, [chatOpenStatus]);

  const fnDateFormat = (date) => {
    return date ? moment(date).format('DD-MM-YYYY') : '';
    // return date ? moment(date).format('DD-MM-YYYY hh:mm:ss A') : '';
  }

  return (
    <div>
      {chatOpenStatus === false ? <Fab color="primary" variant="extended"
        style={{ position: "fixed", right: 5, bottom: 5, zIndex: 9999 }}
        onClick={() => handleOpen('default')}>
        <NavigationIcon style={{ marginRight: '5px' }} /> SOS
      </Fab> : <div></div>}

      <Modal className={classes.modal} open={open} onClose={handleClose}
        aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" hideBackdrop={true}
        disableAutoFocus={true} style={{ zIndex: 0, position: "initial" }} >
        <div className={classes.modalpaper}>
          <div className={classes.modalDetails}>
            <div>
              <Typography variant="h5" color="textPrimary"> Chat </Typography>
            </div>

            <div className={classes.closeicn}>
              <RefreshIcon style={{ marginRight: "10px" }} onClick={() => handleRefresh(selectedChatId)} />
              <RemoveIcon onClick={handleClose} />
            </div>
          </div>

          <div className='page'>
            <div className='container'>
              <div className={classes.sidebar}>
                <div >
                  <TextField id="outlined-basic" variant="outlined" fullWidth placeholder='Search..' className={classes.seacrhbox} />
                  <div className={classes.chats}>

                    {
                      userSelectedChatList && userSelectedChatList.length ? userSelectedChatList.map((chatObject) => {
                        return (
                          <div key={chatObject?.sosChat?.id}>
                            <div className={classes.chatlistselect} style={{ backgroundColor : (chatObject?.sosChat?.id == selectedChatId) ? '#09326324' : ''}}>
                              <div style={{ display: "flex", width: "190px", }} onClick={() => { 
                                dispatch(setChatId(chatObject?.sosChat?.id)); handleRefresh(chatObject?.sosChat?.id) 
                              }}>
                                <Avatar className={classes.avatar} src={chatObject?.employee?.photo} />
                                <Box >
                                  <Typography color="inherit" style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {chatObject?.employee?.firstName} {chatObject?.employee?.lastName}
                                  </Typography>
                                  <Typography noWrap color="inherit" variant="caption" >
                                    {chatObject?.sosChat?.lastMessage}
                                  </Typography>
                                </Box>
                              </div>

                              <div style={{ display: 'grid', justifyContent: 'end' }}>
                                <Typography noWrap color="inherit" variant="caption" style={{ paddingRight: "10px" }} >
                                {fnDateFormat(chatObject?.sosChat?.createdAt)}
                                </Typography>

                                <span style={{position: 'relative', right: '-44px', fontSize: '7px', color: 'red'}}
                                  onClick={() => { dispatch(deleteUserSelectedChat(chatObject?.sosChat?.id))}}>
                                  <CloseRoundedIcon />
                                </span>
                                
                              </div>
                            </div>

                            <Divider />
                          </div>
                        )                     
                      }) : <div></div>
                    }

                  </div>
                </div>
                <div className={classes.root}>
                  <Box flexGrow={1} style={{ marginTop: '10px', borderRadius: '4px', backgroundColor: '#f1f1f1' }}
                    overflow="hidden" >
                    <MessageList thread={null} />
                  </Box>

                  <Divider />
                  {selectedChatId ? <MessageComposer disabled onSend={handleSendMessage} adminImage={""} /> : <div></div>}
                </div>
              </div>
            </div>

          </div>
        </div>

      </Modal>
    </div>
  )
}

export default Floatingchat;