import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { AdminStateData } from 'src/types/admin';
import {toastr} from 'react-redux-toastr';

const initialState: AdminStateData = {
    adminList: [],
    selectedAdminObject: null,
    page: 1,
    limit: 5,
    totalCount: 0,
    loader: false,
    deleteRecId: null,
    currentTabStatus: 'All',
    adminStatusDropdownList: []
};

const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setAdminList(state: AdminStateData, action: PayloadAction<{ adminList: any[], totalCount: number }>) {
            state.adminList = action.payload.adminList;
            state.totalCount = action.payload.totalCount;
        },
        setAdminRelatedDropdownList(state: AdminStateData, action: PayloadAction<{ adminStatusDropdownList: any[] }>) {
            state.adminStatusDropdownList = action.payload.adminStatusDropdownList;
        },
        setAdminSingleData(state: AdminStateData, action: PayloadAction<{ selectedAdminObject: any }>) {
            state.selectedAdminObject = action.payload.selectedAdminObject;      
        },
        loader(state: AdminStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: AdminStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setCurrentSelectedTabStatus(state: AdminStateData, action: PayloadAction<{ tabStatus: string }>) {
            state.currentTabStatus = action.payload.tabStatus;
        },
    }
});

export const reducer = slice.reducer;

export const getAllAdminList = (page: number = 0, limit: number = 10, searchKey: string = '', empStatus: string = 'All'): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/admin?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&empStatus=${empStatus}`, {}, {});

    dispatch(slice.actions.setAdminList({ adminList: response?.data || [], totalCount: response?.totalCount || 0 }))

    dispatch(slice.actions.loader({ loader: false }));
};


export const createAdmin = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/admin', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        // history.replace(`/app/admin/create-admin/${response?.data.id}`);
        dispatch(slice.actions.setAdminSingleData({ selectedAdminObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const updateAdmin = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/admin/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setAdminSingleData({ selectedAdminObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;

};

export const getAdminById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/admin/' + id, {}, {});

    dispatch(slice.actions.setAdminSingleData({ selectedAdminObject: response?.data || null }));
};

export const deleteAdminById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/admin/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted || false;
};

export const getAdminCheckPointMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/check-point-master/dropdown-data', {}, {});

    if (response) {
        dispatch(slice.actions.setAdminRelatedDropdownList({ 
            adminStatusDropdownList: response?.data?.employeeStatus || [],
        }));
    }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetAdminForm = () => (dispatch) => {
    dispatch(slice.actions.setAdminSingleData({ selectedAdminObject: null }))
}

export const setCurrentTabStatus = (tabStatus) => (dispatch) => {
    dispatch(slice.actions.setCurrentSelectedTabStatus({ tabStatus: tabStatus }))
}
/* #endregion custom functions */


export default slice;
