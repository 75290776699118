import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { VendorList } from 'src/types/vendor';

const initialState: VendorList = {
    vendorList: [],
    siteDropdownList: [],
    loader: false,
    totalCount: 0,
    vendor: null,
    skip: 0,
    limit: 0,
    query: '',
    deleteRecId: null,
    driverList: [],
    driverTotalCount: 0,
    selectedDriverObject: null,
    vehicleList: [],
    vehicleTotalCount: 0,
    selectedVehicleObject: null,
    logsList: [],
    logsTotalCount: 0
};

const slice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        loader(state: VendorList, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setVendorList(state: VendorList, action: PayloadAction<{ vendorList: any[], totalCount: number, skip, limit, query }>) {
            state.vendorList = action.payload.vendorList;
            state.totalCount = action.payload.totalCount;
            state.skip = action.payload.skip
            state.limit = action.payload.limit
            state.query = action.payload.query
        },
        setSiteDropdownList(state: VendorList, action: PayloadAction<{ siteDropdownList: any[] }>) {
            state.siteDropdownList = action.payload.siteDropdownList;
        },
        setVendor(state: VendorList, action: PayloadAction<any>) {
            state.vendor = action.payload.vendor
        },
        setDeleteRecordId(state: VendorList, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setDriverList(state: VendorList, action: PayloadAction<{ driverList: any[], driverTotalCount: number }>) {
            state.driverList = action.payload.driverList;
            state.driverTotalCount = action.payload.driverTotalCount;
        },
        setDriverSingleData(state: VendorList, action: PayloadAction<{ selectedDriverObject: any }>) {
            state.selectedDriverObject = action.payload.selectedDriverObject;      
        },
        setVehicleList(state: VendorList, action: PayloadAction<{ vehicleList: any[], vehicleTotalCount: number }>) {
            state.vehicleList = action.payload.vehicleList;
            state.vehicleTotalCount = action.payload.vehicleTotalCount;
        },
        setVehicleSingleData(state: VendorList, action: PayloadAction<{ selectedVehicleObject: any }>) {
            state.selectedVehicleObject = action.payload.selectedVehicleObject;      
        },
        setLogsList(state: VendorList, action: PayloadAction<{ logsList: any[], logsTotalCount:number }>) {
            state.logsList = action.payload.logsList
            state.logsTotalCount = action.payload.logsTotalCount
        }
    }
});
 
export const reducer = slice.reducer;

export const getAllVendors = (page: number = 0, limit: number = 10, searchKey: string = '', empStatus: string = 'All'): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/vendor?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setVendorList({ skip: page, limit, query: searchKey, vendorList: response?.data?.list || [], totalCount: response?.data?.totalCount || 0 }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const createVendor = (payload, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/vendor', payload, {});

    if (response?.data?.id) {
        // toastr.success('Success', response?.message);
        snackBar('Vendor Added', {
            variant: 'success',
        });
        // dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const updateVendor = (id, payload, snackBar): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/vendor/${id}`, payload, {});
    if (response?.data?.id) {
        snackBar('Vendor Updated', {
            variant: 'success',
        });
        // toastr.success('Success', response?.message);
        // dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;

};

export const getSiteDropdownMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/site-master', {}, {});

    if (response) {
        dispatch(slice.actions.setSiteDropdownList({ 
            siteDropdownList: response?.data || [],
        }));
    }
};

export const deleteVendorById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/vendor/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

  return response?.data?.isDeleted == true ? true : false;

};

export const getVendorById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/vendor/' + id, {}, {});
    dispatch(slice.actions.setVendor({ vendor: response?.data || null }));
};

/* #region driver */
export const createDriver = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/driver', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const updateDriver = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/driver/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setDriverSingleData({ selectedDriverObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;

};

export const getAllDrivers = (page: number = 0, limit: number = 10, searchKey: string = '', vendor: number | string): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/driver/_all/v1?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&vendor=${vendor}`, {}, {});

    dispatch(slice.actions.setDriverList({ driverList: response?.data?.list || [], driverTotalCount: response?.data?.totalCount || 0 }))
};

export const getDriverById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/driver/' + id, {}, {});

    dispatch(slice.actions.setDriverSingleData({ selectedDriverObject: response?.data || null }));

    return response?.data?.id ? true : false;
};

export const deleteDriverById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/driver/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

  return response?.data?.isDeleted == true ? true : false;

};
/* #endregion drivers */

/* #region vehicles */
export const createVehicle = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/vehicle', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const updateVehicle = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/vehicle/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setVehicleSingleData({ selectedVehicleObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;

};

export const getAllVehicles = (page: number = 1, limit: number = 10, searchKey: string = '', vendor: string | number): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/vehicle/_all/v1?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&vendor=${vendor}`, {}, {});

    dispatch(slice.actions.setVehicleList({ vehicleList: response?.data || [], vehicleTotalCount: response?.totalCount || 0 }))
};

export const getVehicleById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/vehicle/' + id, {}, {});

    dispatch(slice.actions.setVehicleSingleData({ selectedVehicleObject: response?.data || null }));

    return response?.data?.id ? true : false;
};

export const deleteVehicleById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/vehicle/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

  return response?.data?.isDeleted == true ? true : false;

};

export const getAllLogs = (page: number = 0, limit: number = 10, searchKey: string = '', type: number | string = '', vendor: number | string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/vendor/logs/all?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&vendor=${vendor}`, {}, {});

    dispatch(slice.actions.setLogsList({ logsList: response?.data?.list || [], logsTotalCount: response?.data?.totalCount || 0 }))
};
/* #endregion vehicles */

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const clearVendor = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setVendor({ vendor: null }));
};

export const resetDriverForm = () => (dispatch) => {
    dispatch(slice.actions.setDriverSingleData({ selectedDriverObject: null }));
}

export const resetVehicleForm = () => (dispatch) => {
    dispatch(slice.actions.setVehicleSingleData({ selectedVehicleObject: null }));
}
/* #endregion custom functions */

export default slice;
