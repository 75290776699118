import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { EmployeeStateData } from 'src/types/employee';
import { toastr } from 'react-redux-toastr';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: EmployeeStateData = {
    employeeList: [],
    userTypeDropdownList: [],
    requestLogTypeDropdownList: [],
    employeeStatusDropdownList: [],
    tripLogStatusDropdownList: [],
    genderDropdownList: [],
    selectedEmployeeObject: null,
    page: 1,
    limit: 5,
    totalCount: 0,
    loader: false,
    deleteRecId: null,
    currentTabStatus: 'All',
    employeeAddressList: [],
    employeeAddressTotalCount: 0,
    selectedEmployeeAddressObject: null,
    requestAddress: [],
    requestAddressCount: 0,
    logsList: [],
    logsTotalCount: 0,
    shiftDropdownList: [],
    shiftDropdownListTotalCount: 0,
    submitLoader: false,
    passwordResetLoader: false
};

const slice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployeeList(state: EmployeeStateData, action: PayloadAction<{ employeeList: any[], totalCount: number }>) {
            state.employeeList = action.payload.employeeList;
            state.totalCount = action.payload.totalCount;
        },
        setEmployeeRelatedDropdownList(state: EmployeeStateData, action: PayloadAction<{
            userTypeDropdownList: any[], requestLogTypeDropdownList: any[],
            employeeStatusDropdownList: any[], tripLogStatusDropdownList: any[], genderDropdownList: any[]
        }>) {

            state.userTypeDropdownList = action.payload.userTypeDropdownList;
            state.requestLogTypeDropdownList = action.payload.requestLogTypeDropdownList;
            state.employeeStatusDropdownList = action.payload.employeeStatusDropdownList;
            state.tripLogStatusDropdownList = action.payload.tripLogStatusDropdownList;
            state.genderDropdownList = action.payload.genderDropdownList;

        },
        setEmployeeSingleData(state: EmployeeStateData, action: PayloadAction<{ selectedEmployeeObject: any }>) {
            state.selectedEmployeeObject = action.payload.selectedEmployeeObject;
        },
        loader(state: EmployeeStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: EmployeeStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setCurrentSelectedTabStatus(state: EmployeeStateData, action: PayloadAction<{ tabStatus: string }>) {
            state.currentTabStatus = action.payload.tabStatus;
        },
        setEmployeeAddressList(state: EmployeeStateData, action: PayloadAction<{ employeeAddressList: any[], employeeAddressTotalCount: number }>) {
            state.employeeAddressList = action.payload.employeeAddressList;
            state.employeeAddressTotalCount = action.payload.employeeAddressTotalCount;
        },
        setEmployeeAddressSingleData(state: EmployeeStateData, action: PayloadAction<{ selectedEmployeeAddressObject: any }>) {
            state.selectedEmployeeAddressObject = action.payload.selectedEmployeeAddressObject;
        },
        setRequests(state: EmployeeStateData, action: PayloadAction<{ requests: any, totalCount: number }>) {
            state.requestAddress = action.payload.requests
            state.requestAddressCount = action.payload.totalCount
        },
        setLogsList(state: EmployeeStateData, action: PayloadAction<{ logsList: any[], logsTotalCount: number }>) {
            state.logsList = action.payload.logsList
            state.logsTotalCount = action.payload.logsTotalCount
        },
        setShiftDropdownList(state: EmployeeStateData, action: PayloadAction<{ shiftDropdownList: any[], shiftDropdownListTotalCount: number }>) {
            state.shiftDropdownList = action.payload.shiftDropdownList;
            state.shiftDropdownListTotalCount = action.payload.shiftDropdownListTotalCount;
        },
        setSubmitLoader(state: EmployeeStateData, action: PayloadAction<any>) {
            state.submitLoader = action.payload.submitLoader;
        },
        setPasswordResetLoader(state: EmployeeStateData, action: PayloadAction<any>) {
            state.passwordResetLoader = action.payload.passwordResetLoader;
        },
    }
});

export const reducer = slice.reducer;

export const getAllEmployees = (page: number = 0, limit: number = 10, searchKey: string = '', empStatus: string = 'All'): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/employee?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&empStatus=${empStatus}`, {}, {});

    dispatch(slice.actions.setEmployeeList({ employeeList: response?.data?.list || [], totalCount: response?.data?.totalCount || 0 }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const getAllRequests = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/address/get-all?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setRequests({ requests: response?.data || [], totalCount: response?.totalCount || 0 }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const createEmployee = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setSubmitLoader({ submitLoader: true }));
        const response: any = await AXIOS_POST('/api/employee', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: response?.data?.id ? response?.data : null }));
        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setSubmitLoader({ submitLoader: false }));
    }
};

export const resetEmployeePassword = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setPasswordResetLoader({ passwordResetLoader: true }));
        const response: any = await AXIOS_POST('/api/auth/reset-password', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setPasswordResetLoader({ passwordResetLoader: false }));
    }
};

export const updateEmployee = (id: string | number, payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setSubmitLoader({ submitLoader: true }));
        const response: any = await AXIOS_PATCH(`/api/employee/${id}`, payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: response?.data?.id ? response?.data : null }));
        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setSubmitLoader({ submitLoader: false }));
    }
};

export const updateRequestData = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/address/status/${id}`, payload, {});
    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(getAllRequests())
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;

};

export const getEmployeeById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/employee/' + id, {}, {});

    dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: response?.data || null }));
};

export const deleteEmployeeById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/employee/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted || false;
};

export const getEmployeeCheckPointMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/check-point-master/dropdown-data', {}, {});

    if (response) {
        dispatch(slice.actions.setEmployeeRelatedDropdownList({
            userTypeDropdownList: response?.data?.userType || [],
            requestLogTypeDropdownList: response?.data?.requestLogType || [],
            employeeStatusDropdownList: response?.data?.employeeStatus || [],
            tripLogStatusDropdownList: response?.data?.tripLogStatus || [],
            genderDropdownList: response?.data?.gender || []
        }));
    }
};

export const getAllShiftDropdownList = (page: number = 0, limit: number = 100, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/shift?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setShiftDropdownList({ shiftDropdownList: response?.data || [], shiftDropdownListTotalCount: response?.totalCount || 0 }));

    dispatch(slice.actions.loader({ loader: false }));
};


/* #region address */
export const createEmployeeAddress = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/address', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const updateEmployeeAddress = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/address/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setEmployeeAddressSingleData({ selectedEmployeeAddressObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;

};

export const getAllEmployeeAddress = (page: number = 1, limit: number = 10, searchKey: string = '', employee: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/address?page=${page}&limit=${limit}&searchKey=${searchKey}&employee=${employee}`, {}, {});

    dispatch(slice.actions.setEmployeeAddressList({ employeeAddressList: response?.data || [], employeeAddressTotalCount: response?.totalCount || 0 }))
};

export const getEmployeeAddressById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/address/' + id, {}, {});

    dispatch(slice.actions.setEmployeeAddressSingleData({ selectedEmployeeAddressObject: response?.data || null }));

    return response?.data?.id ? true : false;
};

export const deleteAddressById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/address/' + id, {});
    // dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;

};

export const importEmployeeFile = (payload: any): AppThunk => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', payload);

    const response: any = await AXIOS_POST('/api/employee/read', formData, {});

    if (response?.data) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getAllLogs = (page: number = 0, limit: number = 10, searchKey: string = '', type: number | string = '', employee: number | string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/employee/logs/all?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&type=${type}&employee=${employee}`, {}, {});

    dispatch(slice.actions.setLogsList({ logsList: response?.data?.list || [], logsTotalCount: response?.data?.totalCount || 0 }))
};
/* #endregion address */

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEmployeeForm = () => (dispatch) => {
    dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: null }))
}

export const setCurrentTabStatus = (tabStatus) => (dispatch) => {
    dispatch(slice.actions.setCurrentSelectedTabStatus({ tabStatus: tabStatus }))
}

export const resetEmployeeAddressForm = () => (dispatch) => {
    dispatch(slice.actions.setEmployeeAddressSingleData({ selectedEmployeeAddressObject: null }))
}
/* #endregion custom functions */


export default slice;
