import axios, { AxiosError } from "axios";

// const BASE_URL = 'https://5f6e-103-224-35-106.ngrok.io';
// export const BASE_URL = 'http://172.20.8.210:4100';
// export const BASE_URL = 'http://96a0-103-146-24-6.ngrok.io'
// export const BASE_URL = 'http://172.20.8.210:4100';
export const BASE_URL = "https://transport-api.wait-wise.com";
// export const BASE_URL = 'http://172.20.8.138:3200';
// export const BASE_URL = 'http://172.20.6.17:4100';
// export const BASE_URL = 'http://172.20.9.24:4100';

const getToken = () => {
    const innertoken = localStorage.getItem('accessToken');
    const token: string = "Bearer " + innertoken
    return token
}

const clearToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userDetails');
    
    document.location.href = '/login';
}

export const AXIOS_POST = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'POST',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });

        return response.data;
    } catch (error) {
        console.log("error", error)
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }

            return error.response;
        } else {
            return null
        }
    }
}

export const AXIOS_GET = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'GET',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            params: body
        });
        return response.data
    } catch (error) {
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }

            return error.response
        } else {
            return null
        }
    }
}


export const AXIOS_PATCH = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'PATCH',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}

export const AXIOS_DELETE = async (url: string, headers: any) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}