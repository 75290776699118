import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

// axiosInstance.interceptors.request.use((config) => {
//   const access_token = sessionStorage.getItem('access_token');
//   config.headers.Authorization = `Bearer ${access_token}`;
//   return config;
// });

export default axiosInstance;
