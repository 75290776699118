import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import type { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import type { Thread } from 'src/types/chat';
import MessageItem from './MessageItem';
import { useSelector } from 'src/store';
import CommonModal from 'src/components/commonModal';
import { useDispatch } from 'src/store';
import { clearCommonModalSpecificObject } from 'src/slices/route';
import { sosChatListen, sosAdminChat, sosEmployeeChat } from 'src/slices/service/socket.service';
import {  sosListenChat } from 'src/slices/sos';
interface MessageListProps {
  className?: string;
  thread?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const MessageList: FC<MessageListProps> = ({ className, thread, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollRef = useRef<any>(null);
  const { selectedConversations } = useSelector(state => state.sos);
  const [commonModalStatus, setCommonModalStatus] = useState(false);
  const [modalRecId, setModalRecIds] = useState(null);
  
  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current._container.scrollTop = scrollRef.current._container.scrollHeight;
      }
    };

    // sosChatListen((cv) => {
    //   console.log("chat listen",cv)
    //   dispatch(sosListenChat(cv))
    // });

    scrollMessagesToBottom();
  }, [selectedConversations]);

  const fnEmployeeModalTrigger = (id) => {
    dispatch(clearCommonModalSpecificObject('employee'));
    setModalRecIds(id); 
    setCommonModalStatus(true);
  }
  
  return (
    <PerfectScrollbar className={clsx(classes.root, className)} options={{ suppressScrollX: true }}
      ref={scrollRef} {...rest} style={{ height:"67vh", overflowY:"scroll" }}>
      
      {/* {thread.messages.map((message) => (
        <MessageItem key={message.id} message={message} thread={thread} />
      ))} */}

      {selectedConversations && selectedConversations.length ? selectedConversations.map((convObject) => (
        <MessageItem key={convObject.id} messageObject={convObject} empObjTrigger={(id) => { fnEmployeeModalTrigger(id) }}
        />
      )) : <div></div>}

      {commonModalStatus && modalRecId && <CommonModal modalOpenStatus={commonModalStatus} moduleId={modalRecId} moduleName="employee" 
        onCloseModal={(status) => { setCommonModalStatus(status) }}></CommonModal>}

    </PerfectScrollbar>
  );
};

MessageItem.propTypes = {
  className: PropTypes.string,
  // @ts-ignore
  thread: PropTypes.object.isRequired
};

export default MessageList;
