import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { RoutesStateData } from 'src/types/routes';
import _ from 'lodash';
import moment from 'moment';

const initialState: RoutesStateData = {
    routesList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    loader: false,
    employeeCount: 0,
    employeeList: [],
    vehicleList: [],
    driver: [],
    checkPoint: [],
    deleteRecId: null,
    cloneRecId: null,
    route: null,
    checkPointsList: [],
    checkPointDropdownList: [],
    routeTypeDropdownList: [],
    logsList: [],
    logsTotalCount: 0,
    routesDropdownList: [],
    routesDropdownListTotalCount: 0,
    importLoader: false,
    commonLoader: false,
    searchKey: null,
    dayOfTrip: null,
    startTime: null,
    commonModalOpenStatus: false,
    commonModalDriverObject: null,
    commonModalVehicleObject: null,
    commonModalEmployeeObject: null,
    routeMarkers: []
};


const slice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        loader(state: RoutesStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setEmployeeInRoute(state: RoutesStateData, action: PayloadAction<{ employeeInfo: any, routeId: number }>) {
            const routeList = current(state).routesList
            let updateRouteList = []
            
            for (const route of routeList) {
                if (route.id == action.payload.routeId) {
                    let employeeInfo = action.payload.employeeInfo
                    const employeeList = route.employee.filter(emp => emp.id !== employeeInfo.id)
                    let newList = [employeeInfo, ...employeeList]
                    updateRouteList.push({ ...route, employee: newList })
                }
                else {
                    updateRouteList.push(route)
                }
            }
            state.routesList = [...updateRouteList]

        },
        setEmployees(state: RoutesStateData, action: PayloadAction<{ employee: any, employeeCount: number, clear: boolean }>) {
            const employeeList = current(state).employeeList;
            state.employeeCount = action.payload.employeeCount;
            
            if (action.payload.clear) {
                state.employeeList = action.payload.employee
            } else {
                state.employeeList = [...employeeList, ...action.payload.employee]
            }

        },
        setVehicles(state: RoutesStateData, action: PayloadAction<{ vehicle: any[] }>) {
            state.vehicleList = action.payload.vehicle
        },
        setDrivers(state: RoutesStateData, action: PayloadAction<{ driver: any[] }>) {
            state.driver = action.payload.driver
        },
        setRouteList(state: RoutesStateData, action: PayloadAction<{ routesList: any[], totalCount: number }>) {
            state.routesList = action.payload.routesList;
            state.totalCount = action.payload.totalCount;
            // state.page = action.payload.skip
            // state.limit = action.payload.limit
        },
        setDeleteRecordId(state: RoutesStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId;
        },
        setCloneRecordId(state: RoutesStateData, action: PayloadAction<{ cloneRecId: string | number }>) {
            state.cloneRecId = action.payload.cloneRecId;
        },
        setRoute(state: RoutesStateData, action: PayloadAction<{ route: any[], coordinates: any[] }>) {
            let routeObject: any = {...action.payload.route};

            if (routeObject?.id) {
                let employeeArray: any[] = [...routeObject?.employee];
                let empPickupTimeArray: any[] = [...routeObject?.empPickupTime];
                let finalEmpPickupTimeArray: any = [];
                
                if (employeeArray.length && empPickupTimeArray.length) {
                    employeeArray.forEach(ele => {
                        let filterdPickupTimeArray: any[] = empPickupTimeArray.filter(pickupObj => ele?.id == pickupObj?.id);
                        console.log('')
                        if (filterdPickupTimeArray.length) finalEmpPickupTimeArray.push(filterdPickupTimeArray[0]);
                    });

                    routeObject['empPickupTime'] = [...finalEmpPickupTimeArray];
                    state.route = {...routeObject};

                } else {
                    state.route = {...routeObject};
                }

            } else {
                state.route = null;
            }

            console.log('routeObject------->', routeObject)
            state.routeMarkers = action.payload.coordinates
        },
        setCheckPointsList(state: RoutesStateData, action: PayloadAction<{ checkPointsList: any[], checkPointDropdownList: any[] }>) {

            let tenpList: any[] = [...action.payload.checkPointsList];
            let cplist: any[] = [];

            if (tenpList.length) {
                tenpList.forEach(ele => {
                    cplist.push({
                        checkPointMaster: ele?.checkPointMaster?.id || null,
                        expPickUpTime24h: ele?.expPickUpTime24h || null,
                        route: ele?.route?.id || null,
                        displayOrder: ele?.displayOrder || null,
                        landmark: ele?.landmark || null
                    });
                });
            } else {
                cplist.push({
                    checkPointMaster: null,
                    expPickUpTime24h: null,
                    route: null,
                    displayOrder: null,
                    landmark: null
                });
            }

            let dropdownList: any = [...action.payload.checkPointDropdownList];
            let orderbyDropdownList: any[] = _.orderBy(dropdownList, ['displayOrder'], ['asc']);

            state.checkPointsList = [...cplist];
            state.checkPointDropdownList = [...orderbyDropdownList];
        },
        setRoutePublishStatus(state: RoutesStateData, action: PayloadAction<{ publishStatus: boolean }>) {
            let cRouteObject: any = { ...current(state).route };
            cRouteObject['isPublished'] = action.payload.publishStatus;
            state.route = { ...cRouteObject };
        },
        setLogsList(state: RoutesStateData, action: PayloadAction<{ logsList: any[], logsTotalCount: number }>) {
            state.logsList = action.payload.logsList
            state.logsTotalCount = action.payload.logsTotalCount
        },
        setRouteTypeMaster(state: RoutesStateData, action: PayloadAction<{ routeTypeDropdownList: any[] }>) {
            state.routeTypeDropdownList = [...action.payload.routeTypeDropdownList];
        },
        setAllRoutesDropdownList(state: RoutesStateData, action: PayloadAction<{ routesDropdownList: any[], routesDropdownListTotalCount: number }>) {
            state.routesDropdownList = [...action.payload.routesDropdownList];
            state.routesDropdownListTotalCount = action.payload.routesDropdownListTotalCount;
        },
        setImportLoader(state: RoutesStateData, action: PayloadAction<any>) {
            state.importLoader = action.payload.importLoader
        },
        setCommonLoader(state: RoutesStateData, action: PayloadAction<any>) {
            state.commonLoader = action.payload.commonLoader
        },
        setRouteListByData(state: RoutesStateData, action: PayloadAction<{ routesList: any[], totalCount: number }>) {
            state.routesList = action.payload.routesList;
            state.totalCount = action.payload.totalCount;
        },
        setCommonModalOpenStatus(state: RoutesStateData, action: PayloadAction<{ commonModalOpenStatus: boolean }>) {
            state.commonModalOpenStatus = action.payload.commonModalOpenStatus
        },
        setCommonModalObject(state: RoutesStateData, action: PayloadAction<{ moduleName: string, moduleResponseObject: any }>) {
            let moduleName: string = action.payload.moduleName;

            switch (moduleName) {
                case 'employee':
                    state.commonModalEmployeeObject = action.payload.moduleResponseObject;
                    break;

                case 'driver':
                    state.commonModalDriverObject = action.payload.moduleResponseObject;
                    break;

                case 'vehicle':
                    state.commonModalVehicleObject = action.payload.moduleResponseObject;
                    break;

                default:
                    break;
            }

        },
        setMarkersAndMessage(state: RoutesStateData, action: PayloadAction<{ markers: any[] }>) {
            state.routeMarkers = action.payload.markers
        }

    }
});

export const reducer = slice.reducer;

export const getAllEmployees = (page: number = 0, limit: number = 10, searchKey: string = '', clear: boolean = false, empStatus: string = 'All'): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/employee?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&empStatus=${empStatus}`, {}, {});

    dispatch(slice.actions.setEmployees({ employee: response?.data?.list || [], employeeCount: response?.totalCount || 0, clear }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const getAllVehicle = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/vehicle/all/v1`, {}, {});

    if (response && response.data && response.data.length)
        dispatch(slice.actions.setVehicles({ vehicle: response.data }))

    dispatch(slice.actions.loader({ loader: false }));
};


export const getAllDrivers = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/driver/all/v1`, {}, {});

    if (response && response.data && response.data.length)
        dispatch(slice.actions.setDrivers({ driver: response.data }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const createRoute = (payload, history): AppThunk => async (dispatch) => {

    const vehicle = payload.vehicle && payload.vehicle.id
    const driver = payload.driver && payload.driver.id
    let employee = []

    for (const emp of payload.employee || []) {
        employee.push(emp.id);
    }

    payload.vehicle = vehicle;
    payload.driver = driver;
    payload.employee = [...employee];

    const response: any = await AXIOS_POST('/api/route', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        if (payload.id) {
            history.push('/app/management/routelist/')
        }
        else {
            history.push('/app/management/routelist/' + response?.data?.id + '/edit')
        }
        // history.replace(`/app/employee/create-employee/${response?.data.id}`);
        // dispatch(slice.actions.setEmployeeSingleData({ selectedEmployeeObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const clearRoute = () => async (dispatch) => {
    dispatch(slice.actions.setRoute({ route: null, coordinates: [] }))
    dispatch(slice.actions.setEmployees({ employee: [], employeeCount: 0, clear: true }))
    dispatch(slice.actions.setVehicles({ vehicle: [] }))
}


export const getAllRoutes = (page: number = 0, limit: number = 10, searchKey: string = '', status: string = 'All', type: string = 'All', startDate: any = new Date()): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/route?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&status=${status}&type=${type}&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(startDate).format('YYYY-MM-DD')}`, {}, {});

    dispatch(slice.actions.setRouteList({ routesList: response?.data || [], totalCount: response?.totalCount || 0 }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const deleteRouteById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/route/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted || false;
};

export const cloneRouteById = (id): AppThunk => async (dispatch) => {
    let payload: any = { routeId: id };
    const response: any = await AXIOS_POST('/api/route/clone', payload, {});
    dispatch(slice.actions.setCloneRecordId({ cloneRecId: null }));

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const bulkPublishRouteByIds = (ids: any[] = [], snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCommonLoader({ commonLoader: true }));
        let params: any = { routeIds: [...ids] }

        const response: any = await AXIOS_POST('/api/route/publish', params, {});
        dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

        if (response?.status == true) {
            snackBar(response?.message, { variant: 'success' });
        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setCommonLoader({ commonLoader: false }));
    }
};

export const bulkUnpublishRouteByIds = (ids: any[] = [], snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCommonLoader({ commonLoader: true }));
        let params: any = { routeIds: [...ids] }

        const response: any = await AXIOS_POST('/api/route/unPublish', params, {});
        dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

        if (response?.status == true) {
            snackBar(response?.message, { variant: 'success' });
        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setCommonLoader({ commonLoader: false }));
    }
};

export const bulkDeleteRouteByIds = (ids: any[] = [], snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCommonLoader({ commonLoader: true }));
        let params: any = { routeIds: [...ids] }

        const response: any = await AXIOS_POST('/api/route/bulk-delete', params, {});

        if (response?.status == true) {
            snackBar(response?.message, { variant: 'success' });
        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, { variant: 'error' });
        return false;

    } finally {
        dispatch(slice.actions.setCommonLoader({ commonLoader: false }));
    }
};

export const getRouteById = (routeId: number): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/route/${routeId}`, {}, {});
    const employees = response?.data?.employee
    let coordinates = [];

    for (const employee of employees) {
        if (employee.currentLocation && employee.currentLocation.lat) {
            coordinates.push({
                coordinate: [Number(employee.currentLocation.lat), Number(employee.currentLocation.lng)],
                popupMessage: employee.currentTripStatus,
                employeeName: (employee.firstName || "")
            })
        }
    }

    dispatch(slice.actions.setRoute({ route: response?.data?.id ? response?.data : null, coordinates }));
    dispatch(slice.actions.loader({ loader: false }));
};

export const getAllCheckPoints = (id): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/check-point/${id}`, {}, {});

    dispatch(slice.actions.setCheckPointsList({
        checkPointsList: response?.data?.checkpoints || [],
        checkPointDropdownList: response?.data?.master || []
    }))

    dispatch(slice.actions.loader({ loader: false }));
};

export const getTypeMaster = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/route/master/type`, {}, {});

    dispatch(slice.actions.setRouteTypeMaster({ routeTypeDropdownList: response?.data || [] }));
};

export const saveCheckPoints = (payload, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST(`/api/check-point/${id}`, payload, {});

    return true;
};

export const publishById = (routeId: number | string): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/route/publish/${routeId}`, {}, {});
    dispatch(slice.actions.setRoutePublishStatus({ publishStatus: response?.isPublished == true ? true : false }));
};

export const unpublishById = (routeId: number | string): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/route/unpublish/${routeId}`, {}, {});
    dispatch(slice.actions.setRoutePublishStatus({ publishStatus: response?.isUnPublished == true ? true : false }));

};

export const getAllLogs = (page: number = 0, limit: number = 10, searchKey: string = '', type: number | string = '', route: number | string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/route/log?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&routeId=${route}`, {}, {});

    dispatch(slice.actions.setLogsList({ logsList: response?.data || [], logsTotalCount: response?.totalCount || 0 }))
};

export const getAllRoutesDropdownList = (skip: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/route/get-all-route?skip=${skip}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setAllRoutesDropdownList({ routesDropdownList: response?.data || [], routesDropdownListTotalCount: response?.totalCount || 0 }))
};

export const importRouteFile = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setImportLoader({ importLoader: true }));

        const formData = new FormData();
        formData.append('file', payload);

        const response: any = await AXIOS_POST('/api/route/import-data', formData, {});

        if (response?.data) {
            snackBar(response?.message, { variant: 'success' });
        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        dispatch(slice.actions.setImportLoader({ importLoader: false }));
    } catch (error) {
        snackBar(error, { variant: 'error' });
    } finally {
        dispatch(slice.actions.setImportLoader({ importLoader: false }));
    }
};

export const getAllRoute = (searchKey: string = '', dateOfTrip: string, startTime: string, routeType: string, endTimeState: string): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/route/get-route-by-date?searchKey=${searchKey}&dateOfTrip=${dateOfTrip}&startTime=${startTime}&routeType=${routeType}&endTime=${endTimeState}`, {}, {});
    dispatch(slice.actions.setRouteListByData({ routesList: response?.data || [], totalCount: response?.totalCount || 0 }))
    dispatch(slice.actions.loader({ loader: false }));
};

export const commonTripLog = (moduleId: number | string, moduleName: string, snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_GET(`/api/trip-log/common/${moduleId}?moduleName=${moduleName}`, {}, {});
        dispatch(slice.actions.setCommonModalObject({ moduleName: moduleName, moduleResponseObject: response?.data?.id ? response?.data : null }))

        if (response?.status == true) {
            // snackBar(response?.message, { variant: 'success' });
        } else {
            // snackBar(response?.message, { variant: 'error' });
        }

        return response?.status == true ? true : false;
    } catch (err) {
        // snackBar(err, { variant: 'error' });
        return false;
    }
};


/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const setCloneRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setCloneRecordId({ cloneRecId: id ? id : null }));
}

export const clearCommonModalSpecificObject = (moduleName: string) => (dispatch) => {
    dispatch(slice.actions.setCommonModalObject({ moduleName: moduleName, moduleResponseObject: null }))
}

export const updateRouteInfo = (eventData) => (dispatch) => {
    if (eventData && eventData.eventName && eventData.eventName == "ROUTE_DETAILS") {
        dispatch(slice.actions.setEmployeeInRoute({ employeeInfo: eventData.data, routeId: eventData.routeId }))
    }
}


export const generateTripSheet = (payload: { routeId: any, startingKm: any, endKm: any }, snackBar): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_POST(`/api/route/generate-tripsheet`, payload, {});

        if (response?.status == true) {
            snackBar(response?.message, { variant: 'success' });
        } else {
            snackBar(response?.message, { variant: 'error' });
        }

        return response?.status == true ? true : false;
    } catch (err) {
        // snackBar(err, { variant: 'error' });
        return false;
    }
};
/* #endregion custom functions */

export default slice;
