import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as JobQueueReducer } from 'src/slices/jobqueue';
import { reducer as employeeReducer } from 'src/slices/employee';
import { reducer as AuthModule } from 'src/slices/auth';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as VendorReducer } from 'src/slices/vendor';
import { reducer as RouteReducer } from 'src/slices/route';
import { reducer as AdminReducer } from 'src/slices/admin';
import { reducer as SOSReducer } from 'src/slices/sos';
import { reducer as shiftReducer } from 'src/slices/shift';
import { reducer as userReducer } from 'src/slices/users';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  jobQueue: JobQueueReducer,
  auth: AuthModule,
  toastr: toastrReducer,
  employee: employeeReducer,
  vendor: VendorReducer,
  route : RouteReducer,
  admin : AdminReducer,
  sos : SOSReducer,
  shift : shiftReducer,
  user : userReducer,
});

export default rootReducer;
