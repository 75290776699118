import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Lightbox } from 'react-modal-image';
import { Avatar, Box, Link, Typography, makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import CommonModal from 'src/components/commonModal';


interface MessageItemProps {
  className?: string;
  messageObject: any;
  thread?: any;
  empObjTrigger?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex'
  },
  avatar: {
    height: 32,
    width: 32
  },
  image: {
    cursor: 'pointer',
    height: 'auto',
    maxWidth: '100%',
    width: 380
  }
}));

const positionChat = (messageObject: any) => {
  let userDetails = localStorage.getItem('userDetails');
  let parsedObject: any = JSON.parse(userDetails);

  if(messageObject.addedBy == 'Employee'){
    return 0;

  } else if(messageObject.addedBy == 'Admin' && (messageObject?.admin?.id !== parsedObject?.id)){
    return 0;

  } else {
    return 'auto';
  }
}

const MessageItem: FC<MessageItemProps> = ({ className, messageObject, empObjTrigger, thread, ...rest }) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);


  useEffect(() => { }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" maxWidth={500} style={{ marginLeft: positionChat(messageObject)}} >
      {/* <Box display="flex" maxWidth={500} ml={messageObject.addedBy === 'Employee' ? 0 : 'auto'} > */}
        <Avatar className={classes.avatar} 
          src={messageObject.addedBy === 'Employee' ? messageObject?.employee?.photo : messageObject?.admin?.photo} />

        <Box ml={2}>
          <Box bgcolor={messageObject.addedBy === 'Employee' ? 'background.default' : '#a90013'}
            color={messageObject.addedBy === 'Employee' ? 'text.primary' : 'secondary.contrastText'}
            borderRadius="borderRadius" boxShadow={1} px={2} py={1} >

            <span style={{ cursor: 'pointer', textDecoration: 'underline', color: '#30367c', fontSize: '15px', fontFamily: "roboto", fontWeight: 600 }} 
              onClick={() => { empObjTrigger(messageObject?.employee?.id) }} >
                {messageObject?.employee?.firstName} {messageObject?.employee?.lastName}
            </span>

            <Box >
              {messageObject.contentType === 'image' ? (
                <Box mt={1} onClick={() =>
                  setSelectedImage(messageObject.addedBy === 'Employee' ? messageObject?.employee?.photo : messageObject?.admin?.photo)} >
                  <img alt="Attachment" className={classes.image} 
                    src={messageObject.addedBy === 'Employee' ? messageObject?.employee?.photo : messageObject?.admin?.photo} />
                </Box>
              ) : (
                <Typography color="inherit" style={{ fontSize: "12px" }}>
                  {messageObject?.message}
                </Typography>
              )}
            </Box>
          </Box>

          <Box mt={1} display="flex" justifyContent="flex-end" >
            <Typography noWrap color="textSecondary" variant="caption" >
              {moment(messageObject.createdAt).fromNow()}
            </Typography>
          </Box>
        </Box>

      </Box>
      {selectedImage && (
        <Lightbox large={selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </div>
  );
};

MessageItem.propTypes = {
  className: PropTypes.string,
  // @ts-ignore
  message: PropTypes.object.isRequired,
  // @ts-ignore
  thread: PropTypes.object.isRequired
};

export default MessageItem;
