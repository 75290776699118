import { io } from 'socket.io-client';
import { BASE_URL } from './apiService';

let socket;

export const initiateSocketConnection = () => {
    socket = io(BASE_URL);
    console.log(`Connecting socket...`);
}

export const disconnectSocket = () => {
    console.log('Disconnecting socket...');
    if (socket) socket.disconnect();
}

export const subscribeToRoute = (cb) => {
    socket.on('ROUTE_DETAILS', cb);
}
export const subscribeToSOSChatInitiated = (cb) => {
    socket.on('SOS_CHAT_INITIATED', cb);
}

export const subscribeToSOSChatResolved = (cb) => {
    socket.on('SOS_CHAT_RESOLVED', cb);
}

export const sosChatListen = (cb: any) => {
    socket.on('SOS_CHAT_LISTEN', cb);
}

export const sosAdminChat = (cb) => {
    socket.emit('SOS_CHAT_ADMIN', cb);
}

export const sosEmployeeChat = (cb) => {
    socket.emit('SOS_CHAT_EMPLOYEE', cb);
}