import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import type { JobQueue, Notification } from 'src/types/notification';
import { AXIOS_GET } from './service/apiService';




const initialState: JobQueue = {
  jobQueueList: [],
  page: 1,
  limit: 10,
  totalCount: 0
};

const slice = createSlice({
  name: 'jobQueue',
  initialState,
  reducers: {
    getJobList(state: JobQueue, action: PayloadAction<{ jobData: any[],totalCount:number }>) {
      // const { job } = action.payload;
      console.log("getJobList",action.payload)
      state.jobQueueList = action.payload.jobData;
      state.totalCount = action.payload.totalCount;
    }
  }
});

export const reducer = slice.reducer;

export const getJobQueue = (): AppThunk => async (dispatch) => {
  console.log("get jobqueue api call")
  const response: any = await AXIOS_GET('/api/jobqueueValue/1',{},{});
  console.log("responses", response)
  dispatch(slice.actions.getJobList({jobData:response.data.queue,totalCount:response.data.totalCount}));
};

export default slice;
