import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useDispatch, useSelector } from 'src/store';
import { commonTripLog } from 'src/slices/route';
import { useSnackbar } from 'notistack';

interface ModalProps {
  className?: string;
  modalOpenStatus: boolean,
  moduleId: any,
  moduleName: string,
  onCloseModal: any
}

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   const top = 50 + rand();
//   const left = 50 + rand();

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    },
    fontWeightMedium: {
      fontWeight: theme.typography.fontWeightMedium
    },
    modalpaper: {

      position: 'absolute',
      backgroundColor: theme.palette.background.paper,

      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: " 40%",
      height: "95vh",
      overflowY: "scroll",
      border: "none",

      // top: "50%",
      left: "60%",
      right: "auto",
      bottom: "auto",

    },
    closeicn: {
      textAlign: "end",
      padding: "10px",
      marginTop: '-5px',
      cursor: "pointer",
      // display: 'flex'
    },
    labl: {
      fontSize: "12px",
      fontWeight: "bold",
      fontFamily: "sans-serif",
      paddingBottom: "5px"
    },
    lablehead: {
      fontWeight: "bold"
    }
  }),
);

const CommonModal: FC<ModalProps> = ({ className, modalOpenStatus, moduleId, moduleName, onCloseModal, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  const {commonModalDriverObject, commonModalEmployeeObject, commonModalVehicleObject} = useSelector(state => state.route);
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCloseModal(false);
  };

  const fnTitleCaseString = (value: string = '') => {
    if (value) {
        return value.split(' ')
            .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(' ');
    } else {
        return '';
    }
}

  useEffect(() => {
    if (modalOpenStatus) {
      setOpen(true);
      dispatch(commonTripLog(moduleId, moduleName, null));
    }
  }, [modalOpenStatus])

  return (
    <div>
      <Modal className={classes.modal} open={open} onClose={handleClose}
        aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div className={classes.modalpaper}>
          <Grid container justify='space-between'>
            <Grid className={classes.closeicn}>
              <Typography className={classes.lablehead}> {fnTitleCaseString(moduleName)} Details</Typography>
            </Grid>
            <Grid className={classes.closeicn}>
              <CloseOutlinedIcon onClick={handleClose} />
            </Grid>
          </Grid>

          {/* Employee details start */}
          {moduleName === 'employee' ? <Card>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Employee Name
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {(commonModalEmployeeObject?.firstName || commonModalEmployeeObject?.lastName) ?
                        commonModalEmployeeObject?.firstName + " " + ((commonModalEmployeeObject?.lastName && commonModalEmployeeObject?.lastName != 'null') ? commonModalEmployeeObject?.lastName : '')  : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Employee Code
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.empCode || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Email ID
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.email || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Phone Number
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.phoneNumber || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Alternative Phone Number
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.altphoneNumber || '-'}

                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Gender
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.gender || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Year of Birth
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.yearOfBirth || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                    Blood Group
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.bloodGroup || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Address 1
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.address[0]?.addressLine1 || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Address 2
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.address[0]?.addressLine2 || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      City
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.address[0]?.city || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      State
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.address[0]?.state || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Country
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.address[0]?.state || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Pincode
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalEmployeeObject?.address[0]?.pincode || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </CardContent>
          </Card> : <div></div>}
          {/* Employee details end */}

          {/* vendor details start */}
          {moduleName === 'vendor' ? <Card>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vendor Name
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {/* {vendor?.name}?\ */}Sanjith
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Email ID
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {/* {vendor?.vendorEmail} */}test@gmail
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Phone Number
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {/* {vendor?.phoneNumber} */}987654657
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Alternative Phone Number
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {/* {vendor?.altphoneNumber} */}876578899
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Address
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {/* {vendor?.vendorAddressline1} 
                {vendor?.vendorAddressline1 ? `, ${vendor?.vendorAddressline1}` : ''}
                {vendor?.vendorCity ? `, ${vendor?.vendorCity}` : ''}
                {vendor?.vendorState ? `, ${vendor?.vendorState}` : ''}
                {vendor?.vendorPincode ? ` - ${vendor?.vendorPincode}` : ''} */}cbe
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>


            </CardContent>
          </Card> : <div></div>}
          {/* vendor details end */}

          {/* vechile details start */}
          {moduleName === 'vehicle' ? <Card>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vehicle No
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.vehiceNo || '-'}                      
                        </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vehicle Make
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.vehiceMake || '-'}                      
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vehicle Model
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.vehiceModel || '-'}                      
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vehicle Capacity
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.vehiceCapacity || '-'}                      
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vehicle Color
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.vehiceColor || '-'}                      

                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Vehicle Cost
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.vehiceCost || '-'}                      
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Driver
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalVehicleObject?.firstName || '-'}                      
                      </Typography>
                    </TableCell>
                  </TableRow> */}

                </TableBody>
              </Table>
            </CardContent>
          </Card> : <div></div>}
          {/* vechile details end */}

          {/* Driver details start */}
          {moduleName === 'driver' ? <Card>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Driver Name
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.name}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Email ID
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverEmail}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Phone Number
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.phoneNumber}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Alternative Phone Number
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.altphoneNumber}

                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Address 1
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverAddressline1}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Address 2
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverAddressline2}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      City
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverCity}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      State
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverState}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Country
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverState}
                      </Typography>
                    </TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Pincode
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        {commonModalDriverObject?.driverPincode}
                      </Typography>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </CardContent>
          </Card> : <div></div>}
          {/* Driver  details end */}

        </div>
      </Modal>
    </div>
  );
}

CommonModal.propTypes = {
  className: PropTypes.string,
};

export default CommonModal;
