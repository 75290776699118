import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { ShiftStateData } from 'src/types/shift';

const initialState: ShiftStateData = {
    shiftList: [],
    shiftTotalCount: 0,
    selectedShiftObject: [],
    loader: false,
    deleteRecId: null
};

const slice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        setShiftList(state: ShiftStateData, action: PayloadAction<{ shiftList: any[], shiftTotalCount: number }>) {
            state.shiftList = action.payload.shiftList;
            state.shiftTotalCount = action.payload.shiftTotalCount;
        },
        setSelectedShiftObject(state: ShiftStateData, action: PayloadAction<{ selectedShiftObject: any }>) {
            state.selectedShiftObject = action.payload.selectedShiftObject
        },
        loader(state: ShiftStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: ShiftStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        }
    }
});

export const reducer = slice.reducer;

export const getAllShiftList = (page: number = 0, limit: number = 10, searchKey: string = '', currentTab: string = 'All'): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/shift?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setShiftList({ shiftList: response?.data || [], shiftTotalCount: response?.totalCount || 0 }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const createShift = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/shift', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const updateShift = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/shift/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const getShiftDetailsById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/shift/${id}`, {}, {});

    dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: (response?.data || []) }));

    return response?.data?.id ? true : false;
};

export const deleteShiftById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/shift/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetSelectedShiftObject = () => (dispatch) => {
    dispatch(slice.actions.setSelectedShiftObject({ selectedShiftObject: [] }))
}
/* #endregion custom functions */

export default slice;
